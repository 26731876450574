// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/bootstrap";
@import "./size";
@import "./color";
@import "./button";
@import "./sidebar";
@import "./custom";

$border-radius: 3px;
$font-weight-bolder: 800;
// Icon pack
@import '~@mdi/font/scss/materialdesignicons';

// Toastify
@import "~toastify-js/src/toastify.css";

// TomSelect
//@import "~tom-select/dist/scss/tom-select.bootstrap5";

// FlatPickr
@import "~flatpickr";

// Dragula
@import "~dragula/dist/dragula.css"; // plugin for drag&drop

// Plugins
@import "./flatpickr";
//@import "./datatable";
@import "./umbrella_collection";
@import "./link";
@import "./toast";
@import "./form";
@import "./vichfile";
@import "./table";
@import "./umbrella_select";
