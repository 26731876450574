.vich-file {
  .vich-file-actions {
    padding-top: 0.5rem;
    display: flex;

    a {
      margin-left: auto;
    }

  }
}