.sidebar-link.current{
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
  margin-bottom: 10px;
  background-color: #dad6ff;
  svg, span{
    color: var(--theme-deafult) !important;
    -webkit-transition: all 0.3s ease !important;
    transition: all 0.3s ease !important;
  }
}