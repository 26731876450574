.table-link {
  color: inherit;
  font-size: 1.4rem;
  line-height: 1.4rem;
  display: inline-block;
  padding-right: .2rem;
  opacity: 0.8;

  &:hover, &:active {
    opacity: 1;
    color: inherit;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }


}